<template>
  <b-link :to="linkToItem">
    <b-card
      :key="item.key"
      no-body
      class="x-scroll-element mx-1 mb-3 p-0 card-blog float-left card-container"
      :style="isDraggable ? 'cursor: move' : 'cursor: pointer'"
      :class="item.isPublished ? isDraggable?' grid-item':'' :isDraggable?'grid-item no-published': 'no-published'"
    >
      <b-card-img :alt="translate(item.name)" :src="getImageSrc(item.logoURL) || getImageSrc(item.bgURL) || projectPlaceholder" :style="isPlaceholder ? 'object-fit: contain;' : ''" />
    
      <div class="d-flex flex-column justify-content-between p-2 pb-1" style="height:215px">
        <b-card-group class="mb-50 flex-column">
          <h4 v-if="translate(item.name)" class="html-text-ellipsis-3 mb-50">
            {{ translate (item.name) }}
          </h4>
          <p
            v-if="item.headline"
            class="text-secondary"
            :class="{'html-text-ellipsis-2' : !isLatam}"
            v-html=" translate(item.headline)"
          />
          <div class="mt-50 d-flex w-100 justify-content-between">
            <p v-if="item.startDate" class="mr-2 mb-0 small">
              {{ $t('form-create-item.start-date') }}:
              <b-badge
                variant="light-primary"
              >
                {{ timestampToTime(item.startDate) }}
              </b-badge>
            </p>
            <p v-if="item.endDate" class="mb-0 small">
              {{ $t('form-create-item.end-date') }}:
              <b-badge
                variant="light-primary"
              >
                {{ timestampToTime(item.endDate) }}
              </b-badge>
            </p>
          </div>
        </b-card-group>
        <!-- <div class="d-flex">
          <span v-if="item.mainType==='Challenge'" class="mr-1 text-muted">
            <img
              src="@/assets/images/icons/bulb-gray.svg"
              style="width: 14px"
              class="icon mr-25"
            >
            {{ item.totalIdeas || 0 }}
          </span>
          <span class="mr-1 text-muted">
            <feather-icon
              role="button"
              size="20"
              :class="
                item.likedByMember
                  ? 'profile-likes'
                  : 'profile-icon'
              "
              icon="HeartIcon"
              class="mr-25 text-muted"
              @click.stop.prevent="toggleLike(item)"
            />
            {{ item.totalLikes || 0 }}
          </span>
        </div> -->
      </div>
    </b-card>
  </b-link>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import { checkPermissions } from '@/@core/utils/roles-utils';
import ProjectPlaceholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'ProjectCard',
  props: {
    item: {
      type: Object,
      required: true,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    itemTypeSingle: {
      type: String,
      default: 'project',
    },
    itemType: {
      type: String,
      default: 'projects',
    },
  },
  data() {
    return {
      isPlaceholder: false,

    };
  },
  computed: {
    collective() {
      return this.$store.getters.currentCollective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canView() {
      return checkPermissions(
        'view',
        this.itemTypeSingle,
        this.loggedMemberRoles,
        this.collective,
      );
    },
    projectPlaceholder() {
      return ProjectPlaceholder;
    },
    goInside() {
      return true; //this.item.isViewerMember;
    },
    linkToItem() {
      // Decide which route to use
      let routeName = null;
      let params = {};
      if(this.goInside){
        routeName = 'Resumen';
        params = {
          communityId: this.item.slug
        }
      } else {
        routeName = this.itemTypeSingle.toLowerCase();
        params = {
          slug: this.item.slug
        }
      }
      return !this.isDraggable ? 
      { 
        name: routeName, 
        params: params 
      } 
      : null;
    },
  },
  methods: {
    isLatam() {
      return this.$store.getters.currentCollective.key === '5e26cee0-0cbc-11ed-89a7-a1d49fe606c5';
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    getImage() {
      if (this.item.logoURL) {
        return this.getImageSrc(this.item.logoURL);
      }
      if (this.item.bgURL) {
        return this.getImageSrc(this.item.bgURL);
      }
      this.isPlaceholder = true;
      return this.projectPlaceholder;
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    timestampToTime(timestamp) {
      return new Date(timestamp).toLocaleDateString(this.$store.state.locale.currentLocale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    },
    async toggleLike(item) {
      await this.$store.dispatch('toggleLike', {
        itemType: 'communitiesOthers',
        storedKey: this.itemType,
        morphType: this.itemTypeSingle,
        key: item.key,
      });
    },
  },

};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.content-container img {
  width: 100%;
}
.card-img {
  height: 160px !important;
}

.no-published {
  filter: grayscale(100%);
  opacity: 0.6;
  border-color: black;
  border-style: dashed !important;
  border-width: 3px !important;
}
.grid-item {
  box-shadow: 0px 0px 10px -2px $primary !important;
}
/* TODO: fix responsive with bootstrap, better than media queries!! */
@media only screen and (max-width: 600px) {
  .cards-container {
    margin-left: -2rem;
  }
}
</style>
